import React from "react"
import styled from "styled-components"
import SecondaryButton from "../styles/SecondaryButton"
import Logo from "../assets/images/b2cwhitelogo.svg"
const FooterStyles = styled.footer`
  background-color: var(--box);
  color: var(--bg);
  .main__footer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 4rem;
    place-items: center;
    ul {
      h3 {
        font-size: 3rem;
        text-transform: uppercase;
      }
      li {
        font-family: "Cormorant Garamond";
      }
    }
  }

  .footer__Ad {
    display: flex;
    gap: 4rem;
    padding: 4rem;
    h1 {
      font-size: 4rem;
    }
    p {
      font-size: 1.5rem;
    }
  }
  @media screen and (max-width: 768px) {
    .main__footer {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
    .footer__Ad {
      flex-direction: column;
    }
  }
`
export default function Footer() {
  return (
    <FooterStyles>
      <div className="footer__Ad">
        <div>
          <h1>ARE YOU GOING TO ABROAD?</h1>
          <p>
            Join Our Barista Courses with discount upto20% with certification of
            the completion
          </p>
        </div>
        <div>
          {" "}
          <SecondaryButton
            text="Join Us"
            link="tel:+977-9810087850
"
          />
        </div>
      </div>
      <div className="main__footer">
        <div>
          <div className="Logo">
            <img src={Logo} alt="BeanstoCupbaristaAcademy white Logo" />
            {/* <p>Brew with confidence, Serve with passion.</p> */}
          </div>
        </div>
        <div className="quick__links">
          <ul>
            <h3>Quick Links</h3>
            <li className="">HOME</li>
            <li className="">COURSES</li>
            <li className="">ABOUT US</li>
            <li className="">CONTACT US</li>
          </ul>
        </div>

        <div className="useful__links">
          <ul>
            <h3>Contact Us</h3>

            <li className="">beanstocupbarista@gmail.com</li>
            <li className="">9810087850</li>
            <li className="">9841006192</li>
          </ul>
        </div>

        <div className="contact__info">
          <h2 className="">Brew with confidence, Serve with passion.</h2>
        </div>
      </div>
    </FooterStyles>
  )
}
