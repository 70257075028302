import { createGlobalStyle } from "styled-components"
// import Fontone from "../assets/fonts/ModernAesthetic-Regular.woff"
import Fonttwo from "../assets/fonts/Thunder-BoldLC.woff"
const GlobalStyles = createGlobalStyle`
:root {
   --main: #dcd6d0;
   --bg: #ffedd2;
   --box: #0d0d0d;
   --text: #383c49;
   --text_fill: #b7bfd4;
}

 @font-face {
   font-family: "Thunder";
   src: url(${Fonttwo}) format("woff");
  
}

*,
*:before,
*:after {
   box-sizing: inherit;
}
html {
   overflow-x: hidden;
}

body {
   font-family: "Thunder";
   padding: 0;
   margin: 0;
   overflow-x: hidden;
   background-color: var(--bg);
   color: var(--box);
   
}
ul {
   list-style : none;
}

p {
   font-family: 'Cormorant Garamond', sans-serif;
   letter-spacing: 0.5px;
   margin: 0;
   padding: 0;
}
h1,
h2,
h3,
h4,
h5 {
padding: 0;
   margin: 0;
   font-family: "Thunder";
}
.center {
   text-align: center;
}
.pad-down {
   margin-bottom: 4rem;
}
a {
   text-decoration: none;
}
/* For Scrollbar custom color for brand consistency */
.my-scrollable-element::-webkit-scrollbar-thumb {
   background-color: #888;
   border-radius: 5px;
}

.my-scrollable-element::-webkit-scrollbar-thumb:hover {
   background-color: #555;
}

.my-scrollable-element::-webkit-scrollbar-track {
   background-color: #f5f5f5;
}
body::-webkit-scrollbar {
   width: 12px;
}
html {
   scrollbar-width: thin;
   scrollbar-color: #ffffff #9e978d;
}
body::-webkit-scrollbar-track {
   background: #ffffff;
}
body::-webkit-scrollbar-thumb {
   background-color: var(--text);
   /* border-radius: 6px; */
}

/* Lazy loading secret Sauce */
.gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

img {
   max-width: 100%;
}

.inner-style {
   max-width: 1400px;
   /* remove this width if necessary */
   width: 100%;
   margin: 0 auto;
}

.active  {
   text-decoration : none; 
 
   position  :  relative;
   transition: background-color 0.3s ease-in;
}
.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 50%;
  height: 2px;
  background-color: var(--box);
  
  border-radius : 32px;
}

`
export default GlobalStyles
