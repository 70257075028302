import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const ButtonStyles = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  /* background: #183153; */
  background-color: var(--bg);

  font-family: "Montserrat", sans-serif;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  border: none;
  cursor: pointer;

  &:after {
    content: " ";
    width: 0%;
    height: 100%;
    /* background: #ffd401; */
    background-color: var(--box);

    position: absolute;
    transition: all 0.4s ease-in-out;
    right: 0;
  }

  &:hover::after {
    right: auto;
    left: 0;
    width: 100%;
  }

  /* span {
  
  } */
  a {
    text-align: center;
    text-decoration: none;
    width: 100%;
    padding: 18px 25px;
    color: var(--box);
    font-size: 1.5em;
    font-weight: 700;
    letter-spacing: 0.1em;
    z-index: 20;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    font-family: "Thunder";
  }

  &:hover a {
    color: var(--bg);
    animation: scaleUp 0.3s ease-in-out;
  }
  @keyframes scaleUp {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(0.95);
    }

    100% {
      transform: scale(1);
    }
  }
`

export default function SecondaryButton({ text, link }) {
  return (
    <ButtonStyles>
      <Link to={link}>
        {" "}
        <span>{text}</span>
      </Link>
    </ButtonStyles>
  )
}
