import React, { memo } from "react"
import Header from "../components/header"
import GlobalStyles from "../styles/GlobalStyles"
import Footer from "../components/Footer"
import { ReactLenis } from "@studio-freight/react-lenis"

const Layout = memo(({ children }) => {
  // const lenis = useLenis(...);

  return (
    <>
      <ReactLenis root>
        <GlobalStyles />
        <Header />
        {children}
        <Footer />
      </ReactLenis>
    </>
  )
})

export default Layout
