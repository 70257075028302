import React, { useState } from "react"
import { Link } from "gatsby"
import UseAnimations from "react-useanimations"
import menu4 from "react-useanimations/lib/menu4"
import Logo from "../assets/images/b2c.svg"
import styled from "styled-components"
import CallUs from "../styles/CallUs"

const NavStyles = styled.nav`
  position: relative;
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  width: 100%;
  padding: 0.5rem 0rem;
  background-color: transparent;
  color: var(--box);

  .brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
  }

  .navigation-menu {
    /* margin-left: auto; */
  }

  .navigation-menu ul {
    display: flex;
    gap: 2rem;
    padding: 0;
    justify-content: center;
    align-items: center;
  }

  .navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }

  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
    color: var(--box);
    font-size: 1.5rem;
    letter-spacing: 1.2px;
    font-weight: 900;
  }

  .hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }

  .hamburger:hover {
    /* background-color: #2642af; */
  }

  @media screen and (max-width: 768px) {
    .hamburger {
      display: block;
    }

    .navigation-menu ul {
      flex-direction: column;
      position: fixed;
      top: 100px;
      left: 0;
      width: 50vw;
      /* height: calc(100vh - 80px); */
      height: 100vh;
      background-color: var(--box);
      /* color: var(--bg); */
      border-top: 1px solid black;
      display: none;
    }

    .navigation-menu li {
      text-align: center;
      margin: 0;
    }

    .navigation-menu li a {
      color: var(--bg);
      width: 100%;
      padding: 1.5rem 0;
    }

    .navigation-menu li:hover {
      /* background-color: var(--bg); */
      color: var(--main);
    }

    .navigation-menu.expanded ul {
      display: block;
    }
    .dot {
      display: none;
    }
    .btn {
      display: grid;
    }
  }
`

export default function Header() {
  const [isMobile, setIsMobile] = useState(false)
  function handleButton() {
    setIsMobile(!isMobile)
  }
  const closeLink = () => {
    setIsMobile(false)
  }
  return (
    <NavStyles className="navigation" data-scroll-section>
      <div className="nav">
        <Link to="/" className="brand-name">
          <img src={Logo} alt="Brain Waves Logo" width={190} height={180} />
        </Link>
      </div>
      <button onClick={handleButton} className="hamburger">
        <UseAnimations animation={menu4} size={65} strokeColor="#000000" />
      </button>

      <div
        className={isMobile ? "navigation-menu expanded" : "navigation-menu"}
      >
        <ul>
          <li>
            <Link to="/" activeClassName="active" onClick={closeLink}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/courses" activeClassName="active" onClick={closeLink}>
              Courses
            </Link>
          </li>
          <li>
            <Link to="/ourstory" activeClassName="active" onClick={closeLink}>
              OurStory
            </Link>
          </li>

          <li>
            <Link to="/contact" activeClassName="active" onClick={closeLink}>
              Contact
            </Link>
          </li>
          <li className="btn">
            <CallUs text="Call Us" />
          </li>
        </ul>
      </div>
    </NavStyles>
  )
}
